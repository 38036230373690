@import '../style/mixins';
@import '../style/colors';

@keyframes shake0 {
  10%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(10px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-10px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shake1 {
  10%, 90% {
    transform: translate3d(10px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(-10px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(10px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(-10px, 0, 0);
  }
}

@keyframes shake2 {
  10%, 90% {
    transform: translate3d(-2px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}

@keyframes shake3 {
  10%, 90% {
    transform: translate3d(2px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(-2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(2px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(-2px, 0, 0);
  }
}

.index-wrapper {
  background: $white;

  padding-bottom: 30px;
  position: relative;
  width: 100%;

  @media (min-width: $sm-break) {
    min-height: 100%;
  }
}

.index-nav-bar {
  flex-shrink: 0;
  height: 80px;
  padding-top: 8px;

  .nav-bar-inner {
    align-items: center;
    display: flex;
    height: 50px;

    .logo {
      align-items: center;
      background-image: url(../img/logo.png);
      background-repeat: no-repeat;
      background-size: 40px 40px;
      box-sizing: content-box;
      display: inline-flex;
      flex-grow: 0;
      flex-shrink: 0;
      height: 40px;
      margin-left: 10px;
      width: 40px;

      @media (min-width: $md-break) {
        width: 110px;
        padding-left: 50px;
      }

      @media (min-width: $lg-break) {
        margin-left: 20px;
      }

      @include themify(night) {
        background-image: url(../img/logo-white.png);
      }

      .text-logo {
        background-image: url(../img/text-logo.png);
        background-size: 100%;
        display: none;
        height: 21px;
        width: 99px;

        @media (min-width: $md-break) {
          display: block;
        }
      }
    }

    .nav-menu {
      align-items: center;
      display: flex;
      flex-grow: 1;
      height: 40px;
      justify-content: space-around;
      margin-left: 10px;

      @media (min-width: $md-break) {
        margin-left: 10px;
      }

      @media (min-width: $lg-break) {
        margin-left: 80px;
      }

      .menu-item {
        align-items: center;
        color: $steel-grey;
        display: inline-flex;
        font-size: 14px;
        margin-right: 10px;
        padding: 12px 0;

        @media (min-width: $md-break) {
          font-size: 18px;
          margin-right: 30px;
        }

        .mi {
          color: $steel-grey;
          font-size: 16px;
          margin-right: 4px;

          @media (min-width: $md-break) {
            font-size: 20px;
            margin-right: 8px;
          }
        }
      }

      .download-menu-item {
        background: linear-gradient(270deg, #2D5AA0 4.68%, #357CE6 98.13%);
        border-radius: 25px;
        color: #fff;
        display: none;
        font-weight: 500;
        margin-right: 10px;
        padding: 12px 20px;

        @media (min-width: $md-break) {
          display: flex;
          align-items: center;
        }

        .label {
          margin-right: 10px;
        }

        .icons {
          display: flex;
          align-items: center;

          .img-apple {
            height: 16px;
            margin-right: 15px;
          }

          .img-google {
            height: 14px;
            margin-right: 12px;
          }

          .img-desktop {
            height: 14px;
          }
        }
      }

      .space {
        display: none;
        flex-grow: 1;

        @media (min-width: $md-break) {
          display: block;
        }
      }
    }
  }

}

.downloads-sm {
  background: linear-gradient(270deg, #2D5AA0 4.68%, #357CE6 98.13%);
  border-radius: 25px;
  color: #fff;
  font-weight: 500;
  display: flex;
  padding: 12px 20px;
  width: 200px;
  margin: 0 auto 40px auto;
  align-items: center;

  @media (min-width: $md-break) {
    display: none;
  }

  .label {
    margin-right: 12px;
  }

  .icons {
    display: flex;
    align-items: center;

    .img-apple {
      height: 16px;
      margin-right: 15px;
    }

    .img-google {
      height: 14px;
      margin-right: 12px;
    }

    .img-desktop {
      height: 14px;
    }
  }
}

$middle-break: 425px;

.intro {
  background: url(../img/friends.jpg) no-repeat center bottom;
  background-size: 160%;
  box-sizing: content-box;
  height: 540px;
  margin: 0 auto 30px auto;
  max-width: 800px;
  position: relative;

  @media (min-width: $xsm-break) {
    background-size: 130%;
  }

  @media (min-width: $middle-break) {
    background-size: 100%;
  }

  @media (min-width: $sm-break) {
    height: 580px;
    max-width: $sm-break;
    margin-bottom: 0;
  }

  @media (min-width: $md-break) {
    height: 760px;
    max-width: $md-break;

  }

  @media (min-width: $lg-break) {
    height: 790px;
    max-width: $lg-break - 200px;
  }

  @media (min-height: 1000px) {
    margin-top: 100px;
  }

  @media (min-height: 1300px) {
    margin-top: 200px;
  }

  .intro-header {
    color: $dusk-blue;
    font-size: 34px;
    font-weight: 700;
    margin: 0 0 10px 0;
    text-align: center;

    @media (min-width: $md-break) {
      font-size: 46px;
    }

    @media (min-width: $lg-break) {
      font-size: 60px;
    }
  }

  .intro-sub-header {
    color: $steel-grey;
    font-size: 18px;
    margin: 0;
    text-align: center;

    @media (min-width: $md-break) {
      font-size: 26px;
    }

    @media (min-width: $lg-break) {
      font-size: 34px;
    }
  }

  .arrow-1 {
    background: url(../img/arrow1.png) no-repeat;
    background-size: 100%;
    height: 30px; // 45px;
    left: 10%;
    position: absolute;
    top: 4%;
    width: 30px; // 45px;

    @media (min-width: $xsm-break) {
      width: 34px;
      height: 34px;
    }

    @media (min-width: $sm-break) {
      left: 20%;
    }

    @media (min-width: $md-break) {
      width: 45px;
      height: 45px;
      top: 8%;
    }
  }

  .cloud-1 {
    animation: shake0 26s cubic-bezier(.36, .07, .19, .97) infinite;
    background: url(../img/cloud1.png) no-repeat;
    background-size: 100%;
    height: 35px; // 57px;
    position: absolute;
    right: 4%;
    top: 4%;
    width: 47px; // 77px;

    @media (min-width: $xsm-break) {
      width: 54px;
      height: 40px;
      top: 8%;
    }

    @media (min-width: $sm-break) {
      width: 70px;
      height: 52px;
      right: 14%;
    }

    @media (min-width: $md-break) {
      width: 77px;
      height: 57px;
    }
  }

  .cloud-2 {
    animation: shake1 26s cubic-bezier(.36, .07, .19, .97) infinite;
    background: url(../img/cloud2.png) no-repeat;
    background-size: 100%;
    height: 30px; // 44px;
    left: 6%;
    position: absolute;
    top: 16%;
    width: 44px; // 64px;

    @media (min-width: $xsm-break) {
      width: 50px;
      height: 34px;
      top: 20%;
    }

    @media (min-width: $sm-break) {
      width: 64px;
      height: 44px;
      left: 12%;
    }
  }

  .arrow-2 {
    background: url(../img/arrow2.png) no-repeat;
    background-size: 100%;
    height: 40px; // 66px;
    position: absolute;
    right: 2%;
    top: 34%;
    width: 40px; // 66px;

    @media (min-width: $xsm-break) {
      right: 6%;
    }

    @media (min-width: $sm-break) {
      width: 46px;
      height: 46px;
      right: 16%;
    }

    @media (min-width: $md-break) {
      width: 66px;
      height: 66px;
      right: 18%;
    }
  }

  .arrow-3 {
    background: url(../img/arrow3.png) no-repeat;
    background-size: 100%;
    height: 20px; // 66px;
    position: absolute;
    right: 3%;
    top: 46%;
    width: 20px; // 66px;

    @media (min-width: $xsm-break) {
      right: 8%;
    }

    @media (min-width: $sm-break) {
      width: 24px;
      height: 24px;
      right: 16%;
    }

    @media (min-width: $md-break) {
      width: 22px;
      height: 22px;
      right: 19%;
    }
  }

  .cloud-3 {
    animation: shake0 26s cubic-bezier(.36, .07, .19, .97) infinite;
    background: url(../img/cloud3.png) no-repeat;
    background-size: 100%;
    height: 84px; // 121px;
    left: -27%;
    position: absolute;
    top: 36%;
    width: 100px; // 144px;

    @media (min-width: $xsm-break) {
      left: -14%;
    }

    @media (min-width: $sm-break) {
      left: 0;
    }

    @media (min-width: $md-break) {
      width: 144px;
      height: 121px;
      left: 1%;
    }
  }

  .arrow-4 {
    background: url(../img/arrow4.png) no-repeat;
    background-size: 100%;
    bottom: 0;
    height: 18px; // 26px;
    position: absolute;
    right: 30%;
    width: 18px; // 26px;

    @media (min-width: $xsm-break) {
      width: 20px;
      height: 20px;
      right: 32%;
    }

    @media (min-width: $sm-break) {
      width: 22px;
      height: 22px;
      right: 36%;
    }

    @media (min-width: $md-break) {
      width: 26px;
      height: 26px;
      right: 38%;
      bottom: 2%;
    }
  }
}

.index-footer {
  display: flex;
  flex-flow: column-reverse;

  justify-content: center;

  @media (min-width: $sm-break) {
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 0;
    flex-flow: row;
    height: 40px;
    justify-content: space-around;
  }

  .space {
    display: none;
    flex-grow: 1;

    @media (min-width: $sm-break) {
      display: block;
    }
  }

  a {
    color: $steel-grey;
    font-size: 14px;
    padding: 6px;
    text-align: center;

    @media (min-width: $sm-break) {
      padding: 0 10px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.about-cloud {

  .about-inner {
    background: #f4f7f9;
    padding: 20px 0;
    position: relative;

    @media (min-width: $md-break) {
      padding: 80px 0;
    }

    .about-content {
      color: #000000;
      margin: auto;
      max-width: 620px;
      padding: 0 20px;
      position: relative;
      text-align: center;

      .about-title {
        color: #000000;
        font-weight: 400;

        span {
          color: $dusk-blue;
          font-weight: 600;
        }

        @media (min-width: $md-break) {
          font-size: 40px;
        }
      }

      .arrow-1 {
        background: url(../img/arrow1-about.png) no-repeat;
        background-size: 100%;
        height: 27px; // 45px;
        position: absolute;
        right: 2%;
        top: -6px;
        width: 27px; // 45px;

        @media (min-width: $xsm-break) {
          right: 12%;
        }

        @media (min-width: $sm-break) {
          right: 22%;
        }
      }

      .arrow-2 {
        background: url(../img/arrow2-about.png) no-repeat;
        background-size: 100%;
        height: 27px; // 45px;
        left: -10%;
        position: absolute;
        top: 22%;
        width: 27px; // 45px;

        @media (min-width: $xsm-break) {
          top: 18%;
          left: 0;
        }

        @media (min-width: $sm-break) {
          top: 28%;
          left: -2%;
        }

      }

    }

    .sub-cloud {
      height: 40px;
      margin-top: 20px;
      position: relative;

      @media (min-width: $sm-break) {
        margin-top: 40px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -50px;
      }

      .cloud-1 {
        animation: shake2 26s cubic-bezier(.36, .07, .19, .97) infinite;
        background: url(../img/cloud1-about.png) no-repeat;
        background-size: 100%;
        height: 42px;
        left: calc(50% - 62px);
        position: absolute;
        top: 2px;
        width: 57px;

        @media (min-width: $sm-break) {
          width: 77px;
          height: 57px;
          left: calc(50% - 82px);
        }
      }

      .cloud-2 {
        animation: shake3 26s cubic-bezier(.36, .07, .19, .97) infinite;
        background: url(../img/cloud2-about.png) no-repeat;
        background-size: 100%;
        height: 30px;
        position: absolute;
        right: calc(50% - 54px);
        top: 0;
        width: 44px;

        @media (min-width: $sm-break) {
          width: 64px;
          height: 44px;
          right: calc(50% - 76px);
        }
      }

      .arrow-1 {
        background: url(../img/arrow3-about.png) no-repeat;
        background-size: 100%;
        height: 30px;
        margin: auto;
        width: 30px;

        @media (min-width: $sm-break) {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .up-cloud {
    background: url(../img/back-clouds-up.png) center repeat-x;
  }

  .down-cloud {
    background: url(../img/back-clouds-down.png) center repeat-x;
  }

  .up-cloud, .down-cloud {
    background-size: 632px 90px;
    height: 90px;

    @media (min-width: $md-break) {
      height: 165px;
      background-size: 100% 100%;
    }
  }

}

.surfer-ss, .mobile-ss {
  height: auto;
  width: 100%;
}

.downloads {
  margin: 20px auto 100px auto;
  max-width: 600px;

  .downloads-title {
    color: $dark-sky-blue;
    font-size: 42px;
    font-weight: 400;
    margin-bottom: 16px;
    text-align: center;
  }

  .downloads-text {
    color: $steel-grey;
    font-weight: 300;
    margin-bottom: 22px;
    text-align: center;
  }

  .download-buttons {
    display: flex;
    justify-content: center;
    padding: 0 10px;

    @media (min-width: $md-break) {
      padding: 0;
    }

    .download-button {
      border-radius: 32px;
      color: #fff;
      display: block;
      line-height: 100%;
      padding: 20px 0;
      text-align: center;
      width: 120px;

      @media (min-width: $md-break) {
        width: 140px;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      &.btn-desktop {
        background: $dark-sky-blue;
      }

      &.btn-ios {
        background: #A7ADAF;
      }

      &.btn-android {
        background: #ADC548;
      }
    }

  }
}

.contacts {

  margin: 90px auto 40px auto;

  @media (min-width: $md-break) {
    margin-bottom: 100px;
  }

  .contacts-title {
    color: $dark-sky-blue;
    font-size: 42px;
    font-weight: 400;
    margin-bottom: 26px;
    text-align: center;

    @media (min-width: $md-break) {
      margin-bottom: 36px;
    }
  }

  .contacts-links {

    @media (min-width: $md-break) {
      display: flex;
      justify-content: center;
    }

    .contacts-link {
      align-items: center;
      color: $steel-grey !important;
      display: flex;
      font-size: 20px;
      justify-content: center;
      margin-bottom: 16px;

      @media (min-width: $md-break) {
        display: inline-flex;
        margin-bottom: 16px;
        font-size: 15px;
      }

      @media (min-width: $lg-break) {
        font-size: 16px;
      }

      .icon {
        margin-right: 4px;
      }

      &:not(:last-child) {
        margin-right: 14px;
      }
    }
  }
}

.static-content {
  margin: auto;
  max-width: 800px;
  padding: 10px;

  .page-title {
    color: $dark-sky-blue !important;
  }

  h1, h2, h3 {
    color: $warm-grey;
  }

  h1 {
    font-size: 32px;

  }

  h2 {
    font-size: 28px;

  }

  h3 {
    font-size: 24px;
    font-weight: 400;
  }

}