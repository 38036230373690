$light-periwinkle: #c2d4f5;
$duck-egg-blue: #eaf2fc;
$steel-grey: #788187;
$warm-grey: #828282;
$charcoal-grey: #3c4449;
$silver: #c1c5c7;
$red: #ff0000;
$red2: #bf3030;
$red3: #e63535;
$red4: #ec3323;
$white: #ffffff;
$white-two: #fcfcfc;
$white-three: #f5f5f5;
$white-four: #e7e7e7;
$dusk-blue: #2d5aa0;
$dark-sky-blue: #357ce6;
$powder-blue: #c4dcff;
$metallic-blue: #526d91;
$dark-grey-blue: #2e3d51;
$white-five: #eeeeee;
$white-six: #f8f8f8;
$dark-indigo: #081c36;
$dusky-blue: #43638e;
$pinkish-grey: #cecece;
$pastel-blue: #96c0ff;
$dark: #273445;
$dusky-blue-two: #42628d;
$gunmetal: #525b63;
$dark-two: #161d26;
$dark-six: #202834;
$green1: #69bb85;
$green2: lightgreen;
$green3: #a1c982;
