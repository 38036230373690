@import '../../style/mixins';
@import '../../style/colors';


.btn-up-vote{
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 18px;
  justify-content: center;
  text-decoration: none;
  width: 18px;
  border: 1px solid rgba($dark-sky-blue, 0.8);
  color: rgba($dark-sky-blue, 0.8);

  svg {
    width: 14px;
    height: 14px;
  }

  &:focus {
    text-decoration: none;
  }

  &.in-progress {
    animation: up-voting-color-change 5s infinite;
    pointer-events: none;
  }

  &.disabled {
    pointer-events: none;
  }

  .btn-inner {
    display: flex;
  }

  &:hover,
  &.voted {
    background-color: $dark-sky-blue;
    color: $white;
  }
}

