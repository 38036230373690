@import '../../style/mixins';
@import '../../style/colors';

.autocomplete-option {
  padding: 4px 0;
}

.nav-bar {
  flex-shrink: 0;
  height: 85px;
  padding-top: 8px;

  .nav-bar-inner {
    align-items: center;
    display: flex;
    height: 50px;

    .logo {
      background-size: 100%;
      border-radius: 50%;
      flex-shrink: 0;
      height: 40px;
      margin: 0 20px 0 10px;
      width: 40px;

      @media (min-width: $lg-break) {
        margin-left: 20px;
      }

      @include themify(day) {
        background-image: url(../img/logo.png);
      }

      @include themify(night) {
        background-image: url(../img/logo-white.png);
      }
    }

    .search-bar {
      align-items: center;
      border-radius: 8px;
      display: flex;
      flex-grow: 1;
      position: relative;
      margin: 0 10px;

      @media (min-width: $xl-break) {
        margin: 0 136px;
      }

      .ant-select-selection {
        background: transparent !important;
      }

      input {
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        padding: 0;
      }

      .search-btn {
        margin-right: -36px;
        box-shadow: none;
        border: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }


      @include themify(day) {
        background-color: $white-three;
        color: $steel-grey;
      }

      @include themify(night) {
        background-color: $dark-grey-blue;
        color: $white-five;
      }
    }

    .alt-controls {
      align-items: center;
      display: flex;
      flex-shrink: 0;
      height: 100%;
      justify-content: center;
      margin-right: 18px;

      .switch-theme {
        align-items: center;
        cursor: pointer;
        display: flex;
        font-size: 20px;
        justify-content: center;
        width: 30px;

        @include themify(day) {
          color: $silver;
        }

        @include themify(night) {
          color: $white-five;
          opacity: 0.5;
        }
      }

      user-select: none;
    }

    .download-menu-item {
      background: linear-gradient(270deg, #2D5AA0 4.68%, #357CE6 98.13%);
      border-radius: 25px;
      color: #fff;
      display: none;
      font-weight: 500;
      margin-right: 10px;
      padding: 12px 20px;

      @media (min-width: $md-break) {
        display: flex;
        align-items: center;
      }

      .label {
        margin-right: 10px;
      }

      .icons {
        display: flex;
        align-items: center;

        .img-apple {
          height: 16px;
          margin-right: 15px;
        }

        .img-google {
          height: 14px;
          margin-right: 12px;
        }

        .img-desktop {
          height: 14px;
        }
      }
    }
  }
}
