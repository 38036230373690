@import '../style/mixins';
@import '../style/colors';

@keyframes claiming-color-change {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.account-page {
  .page-inner {
    display: flex;
    flex-wrap: wrap;

    .left-side {
      @media (min-width: $md-break) {
        width: 198px;
        margin-right: 30px;
      }

      @media (min-width: $lg-break) {
        width: 208px;
      }

      .profile-area {
        padding-left: 10px;
        word-break: break-all;

        @media (min-width: $md-break) {
          padding: 16px 0 0 14px;
        }

        @media (min-width: $lg-break) {
          padding-left: 29px;
        }

        .account-avatar {
          float: left;
          height: 128px;
          margin: 0 10px 24px 0;
          position: relative;
          width: 128px;

          @media (min-width: $md-break) {
            float: none;
            margin-right: 0;
          }

          .user-avatar {
            border: 3px solid $duck-egg-blue;
            height: 128px;
            width: 128px;
          }

          .reputation {
            background: $dark-sky-blue;
            border-radius: 25px;
            bottom: 2px;
            color: $white;
            font-weight: 700;
            left: 0;
            line-height: 1em;
            padding: 4px 12px;
            position: absolute;
          }
        }

        .username {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 12px;
          padding-top: 40px;

          @media (min-width: $md-break) {
            padding-top: 0;
            font-size: 18px;
            font-weight: 700;
          }

          @include themify(day) {
            color: $charcoal-grey;
          }
          @include themify(night) {
            color: $pinkish-grey;
          }

          &:before {
            content: '@';
          }
        }

        .vpower-line {
          background: $duck-egg-blue;
          height: 2px;
          margin-bottom: 8px;

          .vpower-line-inner {
            background: $dark-sky-blue;
            height: 2px;
          }
        }

        .vpower-percentage {
          color: $dark-sky-blue;
          cursor: default;
          flex-shrink: 0;
          font-size: 12px;
          font-weight: 500;
          margin-bottom: 22px;

          &:after {
            content: '%';
          }
        }

        .full-name {
          color: $dark-sky-blue;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 12px;

          @media (min-width: $md-break) {
            font-size: 18px;
            font-weight: 700;
          }
        }

        .about {
          color: $dark-sky-blue;
          margin-bottom: 24px;
          opacity: 0.5;
        }

        .account-prop {
          align-items: flex-start;
          color: $steel-grey;
          cursor: default;
          display: flex;
          margin-bottom: 12px;
          word-break: break-all;

          .holder-tooltip {
            display: flex;
          }

          .mi {
            color: $silver;
            font-size: 16px;
            margin-right: 6px;
          }

          .active-votes-icon {
            align-items: center;
            background: $silver;
            border-radius: 50%;
            display: flex;
            height: 16px;
            justify-content: center;
            margin-left: 1px;
            width: 16px;

            @include themify(day) {
              color: $white;
            }

            @include themify(night) {
              color: $charcoal-grey;
            }
          }
        }

        .account-numbers {
          align-items: flex-start;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 12px;

          .account-prop {
            width: 88px;
          }

          .tooltip-handler {
            align-items: center;
            display: flex;
          }
        }

        .website-link {
          color: $steel-grey;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 180px;
        }

        .divider {
          height: 1px;
          margin-bottom: 24px;
          overflow: hidden;

          @include themify(day) {
            background: $white-four;
          }

          @include themify(night) {
            background: $dark-two;
          }
        }

        .vpower-line,
        .vpower-percentage,
        .about,
        .account-prop,
        .account-numbers,
        .website-link,
        .divider {
          @media (max-width: $md-break - 1px) {
            display: none;
          }
        }
      }
    }

    .right-side {
      flex-grow: 0;
      flex-wrap: wrap;
      width: 100%;

      @media (min-width: $md-break) {
        width: calc(100% - 228px);
      }

      @media (min-width: $lg-break) {
        width: calc(100% - 238px);
      }

      .account-menu {
        display: flex;
        height: 50px;
        margin-bottom: 10px;

        @include themify(day) {
          border-bottom: 1px solid $white-four;
        }

        @include themify(night) {
          border-bottom: 1px solid $dark-two;
        }

        .account-menu-items {
          display: flex;
          justify-content: space-between;
          padding: 0 6px;
          width: 100%;

          @media (min-width: $md-break) {
            flex-shrink: 0;
            padding: 0;
            justify-content: flex-start;
            width: auto;
          }

          .menu-item {
            align-items: center;
            color: $steel-grey;
            display: flex;
            font-size: 16px;
            height: 50px;
            padding: 0 12px;

            @media (min-width: $xsm-break) {
              padding: 0 16px;
            }

            @media (min-width: $md-break) {
              margin-right: 16px;

            }

            &.selected-item {
              border-bottom: 2px solid $dark-sky-blue;
              color: $dark-sky-blue;
              font-weight: 700;
              pointer-events: none;
            }
          }
        }

        .page-tools {
          align-items: center;
          display: none;
          flex-grow: 1;
          justify-content: flex-end;
          padding-right: 18px;

          @media (min-width: $md-break) {
            display: flex;
          }

        }
      }

      .account-cover {
        display: none;
        height: 134px;
        position: relative;
        width: 100%;

        @media (min-width: $md-break) {
          display: block;
        }

        .cover-image {
          background-color: $white-three;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 3px;
          height: 100%;
          position: absolute;
          width: 100%;
          z-index: 1;
        }

        .follow-controls-holder {
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 2;
        }
      }

      @media (min-width: $lg-break) {
        .entry-list {
          .entry-list-body.grid-view {
            align-content: stretch;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            padding-right: 10px;
          }
        }
      }

      .linear-progress {
        margin-bottom: 40px;
      }

      .wallet-section {
        .first-row {
          padding: 4px 0 14px 0;
          text-align: center;

          @media (min-width: $md-break) {
            align-items: center;
            display: flex;
          }

          @include themify(day) {
            border-bottom: 1px solid $white-four;
          }

          @include themify(night) {
            border-bottom: 1px solid $dark-two;
          }

          .unclaimed-rewards {
            @media (min-width: $md-break) {
              display: flex;
              align-items: center;
            }

            .title {
              font-size: 16px;
              font-weight: 500;
              margin-right: 17px;
              margin-bottom: 10px;

              @media (min-width: $md-break) {
                margin-bottom: 0;
              }

              @media (min-width: $lg-break) {
                font-size: 18px;
                font-weight: 700;
              }

              @include themify(day) {
                color: $steel-grey;
              }

              @include themify(night) {
                color: $metallic-blue;
              }
            }

            .rewards {
              align-items: center;
              background-color: $dark-sky-blue;
              border-radius: 25px;
              color: $white;
              display: inline-flex;
              font-weight: 500;
              height: 51px;
              padding: 0 8px;
              text-align: center;
              margin-bottom: 20px;

              @media (min-width: $md-break) {
                display: flex;
                margin-bottom: 0;
              }

              @media (min-width: $lg-break) {
                font-weight: 700;
                padding: 0 12px;
              }

              .reward-type {
                margin-right: 10px;

                &:last-of-type {
                  margin-right: 0
                }
              }
            }
          }

          .estimated-value {
            color: $dark-sky-blue;
            flex-grow: 1;
            font-size: 20px;
            font-weight: 500;

            @media (min-width: $md-break) {
              text-align: right;
              font-size: 22px;
              font-weight: 700;
            }

            @media (min-width: $lg-break) {
              font-size: 24px;

            }
          }
        }

        .second-row {
          margin-bottom: 16px;
          padding-bottom: 16px;

          @include themify(day) {
            border-bottom: 1px solid $white-four;
          }

          @include themify(night) {
            border-bottom: 1px solid $dark-two;
          }

          .funds {
            .fund-info-icon {
              width: 30px;

              &:after {
                align-items: center;

                border-radius: 50%;
                color: $steel-grey;
                content: 'i';
                display: flex;
                font-size: 12px;
                height: 16px;
                justify-content: center;
                opacity: 0.5;
                width: 16px;

                @include themify(day) {
                  border: 2px solid $steel-grey;
                  color: $steel-grey;
                }

                @include themify(night) {
                  border: 2px solid $metallic-blue;
                  color: $metallic-blue;
                }
              }
            }

            .fund {
              border-radius: 8px;
              box-sizing: content-box;
              padding: 16px 16px 0 16px;

              &.alternative {
                @include themify(day) {
                  background: $white-three;
                }

                @include themify(night) {
                  background: lighten($dark-six, 4);
                }
              }

              .fund-line {
                align-items: center;
                display: flex;
                padding-bottom: 16px;

                .fund-title {
                  font-size: 16px;
                  font-weight: 500;
                  width: 110px;

                  @include themify(day) {
                    color: $charcoal-grey;
                  }

                  @include themify(night) {
                    color: $powder-blue;
                  }
                }

                .fund-number {
                  color: $dark-sky-blue;
                  flex-grow: 1;
                  font-size: 14px;
                  font-weight: 500;
                  text-align: right;
                  width: 180px;

                  &.delegated-shares,
                  &.received-shares,
                  &.next-power-down-amount {
                    color: $steel-grey;
                  }

                  &.total-sp {
                    font-weight: 700;
                  }

                  .btn-delegated,
                  .btn-delegatee {
                    border-bottom: 1px dashed $steel-grey;
                    cursor: pointer;
                  }
                }
              }
            }

            .next-power-down {
              align-items: center;
              box-sizing: content-box;
              display: flex;
              padding: 16px 16px 0 16px;

              @include themify(day) {
                color: $steel-grey;
              }

              @include themify(night) {
                color: $metallic-blue;
              }
            }
          }
        }

        .transaction-list {
          .transaction-list-header {
            h2 {
              font-size: 18px;
              margin-bottom: 16px;
            }
          }

          .transaction-list-item {
            border-radius: 8px;
            box-sizing: content-box;
            display: flex;
            padding: 10px;
            font-size: 13px;

            @media (min-width: $lg-break) {
              font-size: 16px;
              padding: 16px;
            }

            .transaction-icon {
              align-items: center;
              border-radius: 50%;
              display: flex;
              font-size: 14px;
              height: 28px;
              justify-content: center;
              margin-right: 10px;
              width: 28px;

              @media (min-width: $lg-break) {
                font-size: 16px;
                height: 32px;
                width: 32px;
              }

              @include themify(day) {
                background: $white;
                color: $steel-grey;
              }

              @include themify(night) {
                background: $metallic-blue;
                color: lighten($metallic-blue, 40);
              }
            }

            .transaction-title {
              display: flex;
              flex-direction: column;
              font-weight: 500;
              width: 30%;

              .transaction-name {
                @include themify(day) {
                  color: $steel-grey;
                }

                @include themify(night) {
                  color: $metallic-blue;
                }
              }

              .transaction-date {
                font-size: 12px;

                @include themify(day) {
                  color: $silver;
                }

                @include themify(night) {
                  color: $warm-grey;
                }
              }
            }

            .transaction-numbers {
              color: $dark-sky-blue;
              flex-grow: 1;
              font-weight: 500;
              text-align: right;

              .number {
                margin-right: 4px;
              }

              @media (min-width: $md-break) {
                margin-right: 10px;
              }

              @media (min-width: $lg-break) {
                margin-right: 30px;
              }
            }

            .transaction-details {
              color: $steel-grey;
              width: 30%;
              word-break: break-all;
              display: none;

              @media (min-width: $md-break) {
                display: block;
              }
            }

            &:nth-child(odd) {
              @include themify(day) {
                background: $white-three;
              }

              @include themify(night) {
                background: lighten($dark-six, 4);
              }
            }

            @include themify(day) {
              &:nth-child(even) {
                .transaction-icon {
                  background-color: $white-three;
                }
              }
            }
          }
        }

        .first-row,
        .second-row,
        .transaction-list {
          padding-left: 8px;
          padding-right: 8px;

          @media (min-width: $md-break) {
            padding-left: 0;
          }
        }
      }
    }
  }
}
