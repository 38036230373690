@import '../../style/mixins';
@import '../../style/colors';

.friends-dialog-content {
  height: 500px;
  overflow: auto;

  .friends-list {
    .friend-search-box {
      padding: 10px 0;
      max-width: 300px;
      margin: auto;
    }

    .friends-list-body {
      display: grid;
      padding: 10px;

      .friends-list-item {
        border-radius: 8px;
        padding: 8px;
        text-align: center;
        cursor: pointer;

        @include themify(day) {
          background: $white-six;
        }

        @include themify(night) {
          background: lighten($dark-six, 1);
        }

        .user-avatar {
          margin: 0 auto 12px auto;
        }

        .friend-name {
          font-weight: 500;
          margin-bottom: 6px;

          @include themify(day) {
            color: $charcoal-grey;
          }

          @include themify(night) {
            color: $pinkish-grey;
          }

          &::before {
            content: '@';
          }
        }

        .friend-full-name {
          font-size: 95%;

          @include themify(day) {
            color: $steel-grey;
          }

          @include themify(night) {
            color: $powder-blue;
          }
        }
      }
      grid-template-columns: repeat(4, 1fr);

      grid-gap: 10px;

      .empty-list {
        @include themify(day) {
          color: $gunmetal;
        }

        @include themify(night) {
          color: $silver;
        }
      }
    }
  }

  .load-more {
    padding: 20px;
    text-align: center;
  }
}
