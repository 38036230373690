@import '../../style/mixins';
@import '../../style/colors';

.list-switch {
  display: none;
  align-items: center;
  font-size: 22px;
  justify-content: center;
  width: 30px;

  @media (min-width: $sm-break) {
    display: flex;
  }

  @include themify(day) {
    color: $light-periwinkle;
  }

  @include themify(night) {
    color: $metallic-blue;
  }

  &.active {
    border-radius: 5px;
    @include themify(day) {
      background: lighten($light-periwinkle, 10);
    }

    @include themify(night) {
      background: darken($metallic-blue, 10);
    }
  }
}
