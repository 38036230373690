.sign-up-dialog-content {
  margin: auto;
  padding: 40px;
  width: 80%;

  .dialog-header {
    .logo {
      margin: 0 auto 20px auto;
      width: 100px;
      
      img {
        width: 100%;
      }
    }

    .dialog-header-text {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 20px;
      padding-bottom: 20px;
      text-align: center;
    }
    .dialog-description-text {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 10px;
      text-align: center;
    }
  }

  .dialog-form {
    p {
      color: $steel-grey;
      margin-bottom: 14px;
      text-align: center;
    }
  }
}

.sign-up-dialog-content {
  .dialog-header {
    .dialog-header-text {
      margin-bottom: 20px;
    }
  }

  .sign-up-success {
    font-size: 16px;

    p {
      display: flex;
      align-items: center;
      justify-content: center;

      .mi {
        font-size: 24px;
        margin-right: 10px;
        color: $green1;
      }
    }
  }
}