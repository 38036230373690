.user-avatar {
  border-radius: 50%;
  background: #ccc;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;

  &.small {
    height: 24px;
    width: 24px;
  }

  &.normal {
    height: 30px;
    width: 30px;
  }

  &.medium {
    height: 40px;
    width: 40px;
  }

  &.large {
    height: 80px;
    width: 80px;
  }

  &.xLarge {
    height: 120px;
    width: 120px;
  }
}
