.entry-index {
  .page-header .left-side, .page-inner .left-side {
    display: none;

    @media (min-width: $sm-break) {
      display: flex;
      margin-right: 20px;
      width: 138px;
      padding-left: 10px;
    }

    @media (min-width: $lg-break) {
      padding-left: 20px;
      width: 160px;
      margin-right: 50px;
    }
  }

  .page-header {
    display: flex;
    flex-wrap: wrap;
    height: 50px;

    .left-side {
      align-items: center;
      height: 50px;

      // same as .tag-list > .tag-list-header
      .tag-list-header {
        font-size: 18px;
        font-weight: 700;
        margin: 0;
      }
    }

    .right-side {
      flex-grow: 1;

      .page-tools {
        align-items: center;
        display: flex;
        height: 50px;
        justify-content: space-between;
        margin-bottom: 0;
        padding: 0 15px;

        @include themify(day) {
          border-bottom: 1px solid $white-four;
        }

        @include themify(night) {
          border-bottom: 1px solid $dark-two;
        }

        .filter-select {
          align-items: center;
          cursor: pointer;
          display: flex;
          font-size: 16px;
          font-weight: 500;
          height: 100%;

          @include themify(day) {
            color: $dark-sky-blue;
          }

          @include themify(night) {
            color: $pastel-blue;
          }

          .label {
            margin-right: 4px;
          }
        }

        &.loading {
          border-bottom: 0;
          height: 49px;
        }
      }
    }
  }

  .page-inner {
    display: flex;
    flex-wrap: wrap;

    .left-side {
      .tag-list {
        padding-top: 32px;

        .tag-list-header {
          font-size: 18px;
          font-weight: 700;
          margin: 0 0 31px 0;
        }

        .tag-list-item {
          border-radius: 25px;
          color: $silver;
          cursor: pointer;
          display: table;
          margin-bottom: 8px;
          padding: 4px 12px;
          text-decoration: none;
          transition: 0.5s;

          @include themify(day) {
            background: $white;
            border: solid 1px $white-four;
          }

          @include themify(night) {
            background: $dark-grey-blue;
            border: solid 1px $dark-two;
          }

          &.selected-item {
            color: $dark-sky-blue;
            cursor: default;
            pointer-events: none;
          }

          &:hover {
            @include themify(day) {
              background: darken($white, 4);
            }

            @include themify(night) {
              background: darken($dark-grey-blue, 4);
            }
          }
        }
      }
    }

    .right-side {
      flex-grow: 0;
      margin-top: 12px;
      width: 100%;

      .linear-progress {
        margin-bottom: 40px;
      }

      @media (min-width: $sm-break) {
        width: calc(100% - 168px);
      }

      @media (min-width: $lg-break) {
        width: calc(100% - 230px);
      }

      @media (min-width: $lg-break) {
        .entry-list {
          .entry-list-body.grid-view {
            align-content: stretch;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            padding-right: 10px;
          }
        }
      }
    }
  }
}