@import '../../style/mixins';
@import '../../style/colors';

.entry-list-item {
  border-bottom: 1px solid #e7e7e7;
  // font-size: 90%;
  margin-top: 20px;

  padding: 0 6px 16px 6px;

  @media (min-width: $sm-break) {
    padding: 0 6px 16px 0;
  }

  @include themify(day) {
    border-bottom: 1px solid $white-four;
  }

  @include themify(night) {
    border-bottom: solid 1px $dark-two;
  }

  &:last-of-type {
    border-bottom-color: transparent;
  }

  .item-header {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 95%;
    height: 24px;
    margin-bottom: 10px;
    overflow: hidden;

    .author-part {
      align-items: center;
      cursor: pointer;
      display: flex;
      margin-right: 10px;

      .author-avatar {
        margin-right: 8px;
      }

      .author {
        font-weight: 700;

        @include themify(day) {
          color: $charcoal-grey;
        }

        @include themify(night) {
          color: $pinkish-grey;
        }

        .author-reputation {
          color: $steel-grey;
          font-weight: 500;

          &::before {
            content: '(';
          }

          &::after {
            content: ')';
          }
        }
      }
    }

    .category {
      border-radius: 25px;
      display: none;
      flex-shrink: 0;
      font-size: 90%;
      margin-right: 12px;
      padding: 2px 8px;
      transition: 0.5s;

      @media (min-width: $xsm-break) {
        display: block;
      }

      @include themify(day) {
        background-color: $silver;
        color: $white;
      }

      @include themify(night) {
        background-color: $dark-grey-blue;
        color: $silver;
      }

      &:hover {
        @include themify(day) {
          background-color: darken($silver, 10);
        }

        @include themify(night) {
          background-color: lighten($dark-grey-blue, 10);
        }
      }
    }

    .space {
      flex-grow: 1;
    }

    .promoted {
      font-size: 90%;
      color: $dark-grey-blue;
      padding: 2px 6px;

      /*
      @include themify(day) {
        color: $silver;
      }

      @include themify(night) {
        color: $gunmetal;
      }*/
    }

    .read-mark {
      background-color: $dark-sky-blue;
      border-radius: 50%;
      flex-shrink: 0;
      height: 4px;
      margin-right: 12px;
      width: 4px;
    }

    .date {
      color: $steel-grey;
      flex-shrink: 0;
    }

    .reblogged {
      align-items: center;
      color: $steel-grey;
      display: inline-flex;
      flex-shrink: 0;
      margin-left: 24px;

      .mi {
        margin-right: 4px;
      }
    }
  }

  .item-body {
    @include clearfix();

    .item-image {
      cursor: pointer;
      margin: 0 0 10px 0;
      max-height: 300px;
      overflow: hidden;

      @media (min-width: $sm-break) {
        align-items: center;
        border-radius: 4px;
        display: flex;
        float: left;
        height: 77px;
        max-height: none;
        justify-content: center;
        margin-right: 17px;
        width: 130px;
      }

      img {
        @media (min-width: $sm-break) {
          border-radius: 4px;
        }

        height: auto;
        width: 100%;
      }
    }

    .item-summary {

      @media (min-width: $sm-break) {
        float: right;
        width: calc(100% - 147px);
      }

      .item-title{
        color: $charcoal-grey;
      }

      .item-title,
      .item-body {
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        display: block;
      }

      .item-title {
        font-weight: 700;
        margin-bottom: 9px;
      }

      .item-body {
        margin-bottom: 13px;
        @include themify(day) {
          color: $steel-grey;
        }

        @include themify(night) {
          color: $silver;
        }
      }
    }

    .item-controls {
      align-items: center;
      cursor: default;
      display: flex;
      font-size: 90%;

      @media (min-width: $sm-break) {
        float: right;
        width: calc(100% - 147px);
      }

      .voting {
        margin-right: 10px;
      }

      .total-payout {
        color: $steel-grey;

        @include themify(night) {
          font-weight: 500;
        }

        &.payout-declined {
          color: lighten($steel-grey, 15);
          text-decoration: line-through;
        }
      }

      .total-payout, .voters, .comments {
        margin-right: 15px;
        @media (min-width: $sm-break) {
          margin-right: 25px;
        }
      }

      .voters,
      .comments,
      .reblog-btn {
        align-items: center;
        color: $steel-grey;
        display: flex;

        @include themify(night) {
          font-weight: 500;
        }

        .mi {
          margin-right: 5px;
          opacity: 0.5;
        }
      }

      .reblog-btn {
        .inner-btn {
          align-items: center;
          color: $steel-grey;
          display: flex;
        }

        &:hover {
          .inner-btn {
            color: $dark-sky-blue;
          }
        }
      }

      .app {
        display: none;
        flex-grow: 1;
        text-align: right;

        @media (min-width: $xsm-break) {
          display: block;
        }

        @include themify(day) {
          color: $silver;
        }

        @include themify(night) {
          color: $gunmetal;
        }
      }
    }
  }
}

.grid-view {
  @media (min-width: $sm-break) {
    .entry-list-item {

      @media (min-width: $lg-break) {
        margin-right: 2%;
        padding-right: 0;
        width: 49%;

        &:nth-child(2n) {
          margin-right: 0;
        }
      }

      @media (min-width: $xxl-break) {
        margin-right: 1%;
        width: 32.6%;

        &:nth-child(2n) {
          margin-right: 1%;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      .item-body {
        .item-image {
          float: none;
          height: 300px;
          margin: 0 0 10px 0;
          width: 100%;

          @media (min-width: $xxl-break) {
            height: 200px;
          }
        }

        .item-summary {
          float: none;
          width: 100%;
        }

        .item-controls {
          float: none;
          width: 100%;
        }
      }
    }
  }
}
