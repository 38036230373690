@import '../../style/mixins';
@import '../../style/colors';

@keyframes item-loading-fadein-out {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

.entry-list-loading-item {
  @include clearfix();
  animation: item-loading-fadein-out 2s infinite;

  margin-top: 20px;
  padding: 0 0 16px 6px;

  @media (min-width: $sm-break) {
    padding: 0 6px 16px 0;
  }

  @include themify(day) {
    border-bottom: 1px solid lighten($white-four, 4);
  }

  @include themify(night) {
    border-bottom: 1px solid $dark;
  }

  .item-header,
  .item-body .item-image,
  .item-body .item-summary *,
  .item-body .item-controls * {
    border-radius: 10px;

    @include themify(day) {
      background: lighten($white-four, 4);
    }

    @include themify(night) {
      background: $dark;
    }
  }

  .item-header {
    height: 17px;
    margin-bottom: 12px;
    @media (min-width: $sm-break) {
      width: 300px;
    }
  }

  .item-body {
    .item-image {
      height: 200px;
      margin: 0 0 10px 0;
      @media (min-width: $sm-break) {
        width: 130px;
        margin: 0 10px 0 0;
        float: left;
        height: 77px;
      }
    }

    .item-summary {

      @media (min-width: $sm-break) {
        float: right;
        width: calc(100% - 147px);
      }

      .item-title,
      .item-body {
        height: 18px;
        margin-bottom: 10px;
      }

      .item-title {

        @media (min-width: $sm-break) {
          width: 300px;
        }

      }
    }

    .item-controls {
      @media (min-width: $sm-break) {
        float: right;
        width: calc(100% - 147px);
      }

      .total-payout,
      .voters,
      .comments,
      .app {
        float: left;
        height: 16px;
        margin-right: 12px;
        width: 20%;

        @media (min-width: $sm-break) {
          width: 85px;
        }
      }

      .app {
        float: right;
        margin-right: 0;
        width: 40px;
      }
    }
  }
}

.grid-view {
  .entry-list-loading-item {
    margin-right: 2%;
    padding-right: 0;
    width: 49%;

    &:nth-child(2n) {
      margin-right: 0;
    }

    @media (min-width: 1600px) {
      margin-right: 1%;
      width: 32.6%;

      &:nth-child(2n) {
        margin-right: 1%;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    .item-body {
      .item-image {
        float: none;
        height: 200px;
        margin: 0 0 10px 0;
        width: 100%;
      }

      .item-summary {
        float: none;
        width: 100%;
      }

      .item-controls {
        float: none;
        width: 100%;

        .voting,
        .total-payout,
        .voters,
        .comments,
        .app {
          width: 60px;
        }
      }
    }
  }
}
